:root {
  --background-color: rgba(0, 0, 0, 0.87);
  --primary-font: 'Roboto';
  --primary-font-small: 14px;
  --primary-font-middle: 16px;
  --primary-font-extra-middle: 20px;
  --primary-font-extra: 24px;
  --primary-box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  --primary-hr-color: rgba(0, 0, 0, 0.40);
}

body {
  padding:0;
  margin:0;
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
p {
  margin: 0;
  padding: 0;
}

code {
  font-family: var(--primary-font);;
}

header {
  background-color: #0000002e;
  padding:16px
}

header p {
  display:none
}

main {
  display:flex;
  justify-content: center;
}

#receipt {
  margin: 24px;
  width: 412px;
  box-shadow: var(--primary-box-shadow);
  padding: 16px 16px;
}

#receiptHeader {
  text-align: center;
  margin-top: 32px;
}

.companyName {
  font-size: var(--primary-font-extra);
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
}

.line-header {
  background: var(--primary-hr-color);
  margin-top: 16px;
}
.line-header-long {
  background: var(--primary-hr-color);
  margin-top: 24px;
}
.user-message {
  font-size: var(--primary-font-middle);
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--background-color);
  margin-top: 24px;
}
.order-number {
  font-size: var(--primary-font-small);
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 8px;
}

/*--------------------------------Order Details --------------------------*/

.order-details-title {
  font-size: var(--primary-font-middle);
  font-weight: 600;
  line-height: 4px;
  letter-spacing: 0.1px;
  color: var(--background-color);
  margin-top: 24px;
}
.order-details-business {
  margin-top: 16px;
  display: flex;
  gap: 8px;
}
.order-details-address-name {
  font-size: var(--primary-font-middle);
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}

.order-details-address-currentAddress {
  font-size: var(--primary-font-middle);
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--background-color);
  margin-top: 7px;
}

/*--------------------------------Order Modifier--------------------------*/
table {
  width:100%;
}
table tr {
  display:flex;
  justify-content:space-between
}

.modifier-name {
  margin-left: 29px;
  font-size: var(--primary-font-small);
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--background-color);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 320px;
}
.modifier-price {
  font-size: var(--primary-font-small);
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}
.modifier-main-name {
  margin: 6px 0 8px 29px;
  font-size: var(--primary-font-small);
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--background-color);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 320px;
}

/*--------------------------------Order Charges--------------------------*/
.product {
  font-weight: 600;
  font-size: var(--primary-font-middle);
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--background-color);
  margin-top: 6px;
}
.product-total {
  font-weight: 600;
  font-size: var(--primary-font-extra-middle);
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--background-color);
  margin-top: 12px;
}
.items {
  font-weight: 600;
  font-size: var(--primary-font-middle);
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}

.total td:nth-child(odd) {
  font-weight: 600;
  font-size: var(--primary-font-middle);
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}
.total td:nth-child(even) {
  font-weight: 600;
  font-size: var(--primary-font-middle);
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}
.tip td:nth-child(odd) {
  font-weight: 600;
  font-size: var(--primary-font-middle);
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}
.tip td:nth-child(even) {
  font-weight: 600;
  font-size: var(--primary-font-small);
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}
.payment-container {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
}
.payment-title {
  margin-bottom: 5px;
}
.cart-number {
  margin-bottom: 5px;
}
.payment-title,.cart-number-title,.cart-number {
  font-weight: 400;
  font-size: var(--primary-font-middle);
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}

.product-name-longer {
  display: -webkit-box;
  /*-webkit-line-clamp: 1;*/
  -webkit-box-orient: vertical;
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  /*white-space: normal;*/
  width: 320px;
}

@media screen and (max-width: 600px) {
  .product-name-longer {
    display: -webkit-box;
    /*-webkit-line-clamp: 1;*/
    -webkit-box-orient: vertical;
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    /*white-space: normal;*/
    width: 240px;
  }
  .product-longer-price {
    /*width: 65px;*/
  }
}